import Swal from 'sweetalert2';
import { Table } from 'reactstrap';
import React, { useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { ImCancelCircle, ImCheckmark2 } from 'react-icons/im';

import { AssignmentRoutingMemberResponse, AssignmentRoutingResponse } from 'data/AssignmentRouting/schemas/AssignmentRouting.schema';

type Props = {
    filteredReports: AssignmentRoutingResponse[] | undefined;
    handleChangeConfigAssignmentRouting: (
        assignmentToUpdate: AssignmentRoutingResponse,
        field: 'validfrom' | 'validto',
        value: string
    ) => void;
    assignmentRoutingMapping: AssignmentRoutingResponse[] | undefined;
    assignmentRoutingMember: AssignmentRoutingMemberResponse[] | undefined;
    handleFetchWorkflowDocList: (sid: string, user: string) => void
};

function AssignmentRoutingTable(props: Props) {
    const {
        filteredReports,
        handleChangeConfigAssignmentRouting,
        assignmentRoutingMapping,
        assignmentRoutingMember,
        handleFetchWorkflowDocList
    } = props;

    const [assignmentEdit, setAssignmentEdit] = useState<AssignmentRoutingResponse>();

    const handleEdit = (assignment: AssignmentRoutingResponse) => {
        handleFetchWorkflowDocList(assignment.sid, assignment.empid);

        const filteredMemberPositionCode = assignmentRoutingMember?.find(assignmentx => {
            return assignmentx.sid === assignment.sid &&
                assignmentx.positioncode === assignment.positioncode &&
                assignmentx.positiongroupid === assignment.authgroup
        })

        if (!filteredMemberPositionCode || filteredMemberPositionCode.countmember <= 1) {
            Swal.fire({
                icon: 'warning',
                text: `Position Code นี้มี User Active ${!filteredMemberPositionCode
                    ? 0
                    : filteredMemberPositionCode?.countmember
                    } คน คุณยืนยันที่จะแก้ไข`,
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    setAssignmentEdit(assignment);
                }
            });
        } else {
            setAssignmentEdit(assignment);
        }

    };

    const validateValidDateTo = (date: string) => {
        const year = Number(date.slice(0, 4));
        const month = Number(date.slice(4, 6)) - 1;
        const day = Number(date.slice(6, 8));
        const validtoDate = new Date(year, month, day);

        return validtoDate >= new Date();
    };

    return (
        <div style={{ overflowX: 'scroll' }}>
            <Table className="table-bordered">
                <thead>
                    <tr
                        className="table-active thead-light text-nowrap text-center"
                        style={{ width: '100%' }}
                    >
                        <th>SID Name</th>
                        <th>AuthGroup</th>
                        <th>AuthGroup Name</th>
                        <th>PositionCode</th>
                        <th>Position Condition</th>
                        <th>Emp ID</th>
                        <th>Emp Name</th>
                        <th>Email Address</th>
                        <th>Valid From (yyyy/mm/dd)</th>
                        <th>Valid To (yyyy/mm/dd)</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredReports && filteredReports.map((assignment, index) => {
                        const filteredMemberPositionCode = assignmentRoutingMember?.find(assignmentValue => {
                            return assignmentValue.sid === assignment.sid
                                && assignmentValue.positioncode === assignment.positioncode
                                && assignmentValue.positiongroupid === assignment.authgroup
                        });
                        const countMember = filteredMemberPositionCode?.countmember;
                        const bgColorClass = countMember == 1
                            ? 'bg-[#FFE699]'
                            : !filteredMemberPositionCode
                                ? 'bg-[#FF9292]'
                                : '';

                        return (
                            <tr key={index} className={`${bgColorClass}`
                            }>
                                <td>{assignment.sid_name}</td>
                                <td>{assignment.authgroup}</td>
                                <td>{assignment.authgroup_name}</td>
                                <td>{assignment.positioncode}</td>
                                <td>{assignment.position_condition}</td>
                                <td>{assignment.empid}</td>
                                <td>{assignment.emp_name}</td>
                                <td>{assignment.email_address}</td>
                                <td>{assignment.validfrom}</td>
                                <td>
                                    {assignmentEdit &&
                                        assignment.positioncode ===
                                        assignmentEdit.positioncode &&
                                        assignment.emp_name ===
                                        assignmentEdit.emp_name &&
                                        assignment.sid === assignmentEdit.sid &&
                                        assignment.sid_name ===
                                        assignmentEdit.sid_name ? (
                                        <div className="flex justify-around">
                                            <input
                                                defaultValue={
                                                    assignment.validto
                                                }
                                                className="w-1/3"
                                                onChange={e => {
                                                    handleChangeConfigAssignmentRouting(assignment, 'validto', e.target.value);
                                                }}
                                            />
                                            <button
                                                onClick={e => {
                                                    handleChangeConfigAssignmentRouting(assignment, 'validto', '99991231');
                                                    setAssignmentEdit(undefined);
                                                }}
                                                className="rounded border border-green-600 p-0.5 text-green-700"
                                            >
                                                Active
                                            </button>
                                            <button
                                                onClick={e => {
                                                    const date = new Date();
                                                    const year = date.getFullYear();
                                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                                    const day = String(date.getDate()).padStart(2, '0');

                                                    handleChangeConfigAssignmentRouting(assignment, 'validto', `${year}${month}${day}`);
                                                    setAssignmentEdit(undefined);
                                                }}
                                                className="rounded border border-red-600 p-0.5 text-red-700"
                                            >
                                                InActive
                                            </button>
                                        </div>
                                    ) : (
                                        assignmentRoutingMapping?.find(
                                            value =>
                                                assignment.positioncode === value.positioncode &&
                                                assignment.emp_name === value.emp_name &&
                                                assignment.sid === value.sid &&
                                                assignment.sid_name === value.sid_name
                                        )?.validto ?? (
                                            <span>{assignment.validto}</span>
                                        )
                                    )}
                                </td>
                                <td className='align-middle'>
                                    <div className="flex gap-2 text-center">
                                        <FaRegEdit
                                            className="text-yellow-800"
                                            onClick={() => {
                                                handleEdit(assignment);
                                            }}
                                        />
                                        {assignmentEdit &&
                                            assignment.positioncode === assignmentEdit.positioncode &&
                                            assignment.emp_name === assignmentEdit.emp_name &&
                                            assignment.emp_name === assignmentEdit.emp_name &&
                                            assignment.sid === assignmentEdit.sid &&
                                            assignment.sid_name === assignmentEdit.sid_name &&
                                            (<ImCancelCircle
                                                className="text-red-500"
                                                onClick={() => {
                                                    setAssignmentEdit(undefined);
                                                }}
                                            />
                                            )}
                                        {assignmentRoutingMapping && !!assignmentRoutingMapping.find(
                                            value =>
                                                assignment.positioncode === value.positioncode &&
                                                assignment.emp_name === value.emp_name &&
                                                assignment.sid === value.sid &&
                                                assignment.sid_name === value.sid_name
                                        ) && (<ImCheckmark2 className='text-green-500' />)}
                                    </div>
                                </td>
                            </tr>
                        );
                    }
                    )}
                </tbody>
            </Table>
        </div >
    );
}

export default AssignmentRoutingTable;
